.memberCard {
  padding: 15px;
  position: relative;
  border: solid black 1px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.member_details {
  /* position: absolute;
  bottom: 20px; */
  width: 100%;
  text-align: center;
  color: black;
  /* left: 50%; */
}
.memberName {
  margin-bottom: 5px;
  font-size: 15px;
  color: black;
  font-weight: 700;
}
.memberLoc {
  font-size: 15px;
  color: black;
  font-weight: 400;
}
.member_img {
  display: block;
  width: auto;
  height: auto;
}