/* Common style variables */
:root {
  --color-main: #fff;
  --color-accent: #000000;
  --color-accent2: #f1f1f1;
  --color-separator: #bebebe;
  
  --color-text: #000000;
  --color-text-invert: #ffffff;

  --color-chat-bubble-receiver: rgb(233, 233, 233);
  --color-chat-bubble-sender: rgb(0, 0, 0);

  --color-ripple: #818181;

  --border-radius-main: 15px;
  --border-radius-box: 9999px;
}

/* Main font(s) for site */
/*
@font-face {
  font-family: 'Poppins';
  src: url('./fonts/Poppins-Regular.ttf') format('truetype');
}
body {
  font-family: 'Poppins', Fallback, sans-serif !important;
}
*/

/* App.css */
.app-container {
  display: flex;
  flex-direction: column;
  min-height: 1px;
}

/* Adjust NavBar's height */
header {
  height: unset;
}

/* Define the styles for NavBar and PageContent components */
header {
  background-color: var(--color-accent);
  color: white;
}

/* Style PageContent component to take up the remaining space */
main {
  flex-grow: 1;
  padding: 20px;
}

/* Slider */
.MuiSlider-rail {
  color: var(--color-accent);
}
.MuiSlider-track {
  color: var(--color-accent);
}
.MuiSlider-thumb {
  color: var(--color-accent);
}


/* Scrollbar style */
/* Works on Chrome, Edge, and Safari */
::-webkit-scrollbar { 
  height: 7px;
  width: 7px;

  position: absolute;
  top: 0;
  right: 0;
}
::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0);
}
::-webkit-scrollbar-thumb {
  background-color: var(--color-accent);
  border-radius: 99px;
}
::-webkit-scrollbar-thumb:hover {
  background-color: var(--color-main);
  border: 2px solid var(--color-accent);
  border-radius: 99px;
}

/* MUI style overwrite */
.MuiListItem-root .MuiTouchRipple-child {
  background-color: red;
}
